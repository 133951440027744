var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("问题反馈")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "label": "关键字搜索（按回车）",
      "prepend-inner-icon": "search",
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.doAction('search_bugs');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-card-subtitle', [_vm._v(" 操作日志为系统用户登录系统后所有的增加、删除、修改操作，不包括浏览和查询操作。 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerBugs,
      "items": _vm.bugList,
      "options": _vm.bugs_options,
      "server-items-length": _vm.bugCount,
      "loading": _vm.bugLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.bugs_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("反馈记录")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer')], 1)];
      },
      proxy: true
    }, {
      key: "item.model",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_item', item, item._id);
            }
          }
        }, [_vm._v(_vm._s(item.model || ''))])];
      }
    }, {
      key: "item.who",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.who ? _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.who._id
          }
        }, [_vm._v(_vm._s(item.who.personal ? item.who.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.when",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "135px"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.when)))])];
      }
    }, {
      key: "item.where.fullPath",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "155px"
          }
        }, [_vm._v(_vm._s(item.where.fullPath))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "155px"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "75px"
          }
        }, [_vm._v(_vm._s(_vm._f("normalStatus")(item.status)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_item', item, item._id);
            }
          }
        }, [_vm._v("open_with")])];
      }
    }])
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "750"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 反馈详情 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_item');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.openItem.who ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("功能模块")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.openItem.model))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("发生时间")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.openItem.when)))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("提交人")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.openItem.who.personal ? _vm.openItem.who.personal.name : _vm.openItem.who.username))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("IP地址")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.openItem.ip_address))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("路由信息")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.openItem.where))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("问题描述")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.openItem.what))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("预期效果")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.openItem.want))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("系统信息")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.openItem.agent))])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("页面截图")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "250",
      "contain": "",
      "src": _vm.openItem.preview ? _vm.openItem.preview : '/static/error/empty_state.svg'
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('open_preview', _vm.openItem, _vm.openItem.preview ? _vm.openItem.preview : '/static/error/empty_state.svg');
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "scrollable": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dlgImage,
      callback: function callback($$v) {
        _vm.dlgImage = $$v;
      },
      expression: "dlgImage"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("页面截图")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_preview');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.activeImage,
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_preview');
      }
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }